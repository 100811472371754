import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { PropsWithStore } from "store";

import "components/CommonClass/common.scss";
import { Button } from "@material-ui/core";
import CameraView from "components/CameraView";
import { ZhuxinMask } from "tf/mask";

interface Props extends PropsWithStore {
  history?: any;
}
interface State {}
@inject("rootStore")
@observer
export default class WelcomePage extends Component<Props, State> {
  componentDidMount = async () => {
    const {
      rootStore: {
        configStore: { deviceId, userConfig },
      },
    } = this.props;
    if (!deviceId) {
      return;
    }
    if (userConfig.procedures.showTerms) {
      this.props.history.push("/terms");
    } else if (userConfig.procedures.prefill) {
      this.props.history.push("/prefill");
    } else if (userConfig.procedures.doForm) {
      this.props.history.push("/form");
    } else {
      this.props.history.push("/body-check");
    }
  };

  accept = () => {
    const {
      rootStore: {
        configStore: { userConfig },
      },
    } = this.props;
    if (userConfig.procedures.showTerms) {
      this.props.history.push("/terms");
    } else if (userConfig.procedures.prefill) {
      this.props.history.push("/prefill");
    } else if (userConfig.procedures.doForm) {
      this.props.history.push("/form");
    } else {
      this.props.history.push("/body-check");
    }
  };

  processFrame = (canvas: HTMLCanvasElement) => {
    const maskResult = ZhuxinMask.detectMask(canvas);
    const hasFace = maskResult && maskResult[0];

    if (hasFace) {
      this.props.history.push("/body-check");
    }
  };

  render() {
    const {
      rootStore: {
        configStore: { userConfig },
      },
    } = this.props;

    return (
      <div className="middle">
        <img src={process.env.PUBLIC_URL + "/zhuxin-1024.png"} alt={"logo"} />
        <h1>签到宝</h1>
        {!userConfig.procedures.doForm ? (
          <CameraView
            backgroundMode={true}
            processFrame={this.processFrame}
            processInterval={500}
          />
        ) : (
          <Button
            onClick={this.accept}
            className="startButton"
            variant="contained"
            color="primary"
          >
            开始核验
          </Button>
        )}
      </div>
    );
  }
}
