import React, { Component } from "react";
import FormView from "components/FormView";
import { PropsWithStore } from "store";
import "./FormDemo.scss";
import { inject, observer } from "mobx-react";

interface Props extends PropsWithStore {
  history?: any;
}
interface State {}
@inject("rootStore")
@observer
export default class FormDemo extends Component<Props, State> {
  state = {
    hasHuman: false,
    hasMask: false,
    ready: false,
  };

  componentDidMount = async () => {
    const {
      rootStore: {
        configStore: { deviceId },
        formStore,
      },
    } = this.props;
    if (!deviceId) {
      this.props.history.push("/setting");
      return;
    }
    formStore.setFormEnd();
    setTimeout(() => {
      this.setState({
        ready: true,
      });
    }, 1000);
  };

  onSubmit = () => {
    this.props.history.push("/body-check");
  };

  render() {
    return <FormView onSubmit={this.onSubmit} />;
  }
}
