import React, { Component } from "react";
import { PropsWithStore } from "store";
import "./SuccessDoneForm.scss";
import { inject, observer } from "mobx-react";

interface Props extends PropsWithStore {
  history?: any;
}
interface State {}
@inject("rootStore")
@observer
export default class SuccessDoneForm extends Component<Props, State> {
  state = {};

  componentDidMount = async () => {
    const {
      rootStore: { configStore, formStore },
    } = this.props;
    configStore.addDetection({
      customData: {
        hasMask: formStore.wearMask,
        questions: formStore.questions,
        temperature: formStore.temperature,
        healthCode: formStore.healthCode,
        faceImage: formStore.faceImage,
        maskImage: formStore.maskImage,
      },
    });
    setTimeout(() => {
      // window.location.reload();
      window.location.href ="/#/welcome";
      configStore.reset();
      formStore.reset();
    }, 5000);
    const audio = new Audio("https://web-demo.zhuxin.ai/media/verified.mp3");
    audio.play();
  };

  render() {
    return <div className="Success">核验完毕，请通行</div>;
  }
}
