import React, { Component } from "react";
import { Fab, Icon, Snackbar } from "@material-ui/core";
import { inject } from "mobx-react";
import { PropsWithStore } from "store";

interface Props extends PropsWithStore {}
interface State {}

@inject("rootStore")
export default class HelpButton extends Component<Props, State> {
  state = {
    helpBarOpen: false,
  };

  handleClose = () => {
    this.setState({
      helpBarOpen: false,
    });
  };

  render() {
    const {
      rootStore: { configStore },
    } = this.props;
    const { helpBarOpen } = this.state;
    return (
      <>
        <Fab
          color="primary"
          style={{
            position: "absolute",
            right: 30,
            bottom: 30,
          }}
          onClick={() => {
            configStore.aliyunDevice?.postEvent("need_help", {}, (res) => {
              console.log(`post need help`, res);
            });
            this.setState({
              helpBarOpen: true,
            });
          }}
        >
          <Icon style={{ color: "white" }}>help_outline</Icon>
        </Fab>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={helpBarOpen}
          autoHideDuration={6000}
          onClose={this.handleClose}
          message="请稍后，我们会有专人前来协助"
        />
      </>
    );
  }
}
