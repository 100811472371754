import React from "react";
import { HashRouter as Router } from "react-router-dom";
import "./App.scss";
import { Provider } from "mobx-react";
import { rootStore } from "store";
import Routes from "./Routes";
function App() {
  return (
    <Router basename="/">
      <Provider rootStore={rootStore}>
        <Routes />
      </Provider>
    </Router>
  );
}

export default App;
