import React, { Component } from "react";
// import * as tf from "@tensorflow/tfjs";
import * as faceapi from "face-api.js";
import { ZhuxinFace, ZhuxinFaceDetection } from "../../tf/face";
// import { ZhuxinMask } from "../../tf/mask";
// import { detectMask } from "../../util/mask-operation";

import { Icon, Fab } from "@material-ui/core";
import CameraView from "../CameraView";
import "./HumanSignup.scss";
import { ZhuxinMask } from "../../tf/mask";
import FaceManagementDrawer from "./FaceMangementDrawer";
import { inject } from "mobx-react";
import { PropsWithStore } from "../../store";

interface Props extends PropsWithStore {}
interface State {}

@inject("rootStore")
export default class HumanSignup extends Component<Props, State> {
  state = {
    ready: false,
    doingProcess: false,
    faceManagementDrawerOpen: false,
    showCountdown: false,
    countdown: 3,
    cameraPlaying: true,
    faceLabel: null,
    hasMask: false,
    showResult: false,
    showFaceNotInBox: false,
    showNoFaceDetected: false,
  };

  cameraView: CameraView;
  maskModel: any;
  countdownInterval: any;
  detectionBoxElement: any;
  rootElement: any;

  componentDidMount = async () => {
    Promise.all([ZhuxinMask.loadModel(), ZhuxinFace.loadModel()]);
    setTimeout(() => {
      this.setState({
        ready: true,
      });
    }, 1000);
  };

  processFrame = async (
    canvas: HTMLCanvasElement,
    resultCanvas: HTMLCanvasElement
  ) => {
    // const results = ZhuxinMask.detectMask(canvas);
    // ZhuxinMask.drawResults(resultCanvas, results);
    try {
      const detections = await faceapi.detectSingleFace(
        canvas,
        new faceapi.TinyFaceDetectorOptions()
      );
      // .withFaceLandmarks()
      // .withFaceDescriptor();

      if (this.checkDetectionInBox(canvas, detections)) {
        this.setState({ showFaceNotInBox: false });
        // console.log("Has detection in center");
        this.startCountdown();
      } else {
        this.setState({ showFaceNotInBox: true });
        // console.log("Has no detection in center");
        this.resetCountdown();
      }

      const ctx = resultCanvas.getContext("2d");
      ctx.clearRect(0, 0, resultCanvas.width, resultCanvas.height);
      faceapi.draw.drawDetections(resultCanvas, detections);
    } catch (err) {}
  };

  checkDetectionInBox = (
    canvas: HTMLCanvasElement,
    detection: ZhuxinFaceDetection
  ) => {
    if (!!detection) {
      this.setState({ showNoFaceDetected: false });
      const box = detection.box;
      const boxSizeMatch = box.width > 200 && box.height > 200;
      const boxMidWidth = box.left + box.width / 2;
      const boxMidHeight = box.top + box.height / 2;
      const xOutlier = 70;
      const yOutlier = 50;
      const boxPositionMatch =
        boxMidWidth > canvas.width / 2 - xOutlier &&
        boxMidHeight < canvas.width / 2 + xOutlier &&
        boxMidHeight > canvas.height / 2 - yOutlier &&
        boxMidHeight < canvas.height / 2 + yOutlier;
      return boxSizeMatch && boxPositionMatch;
    } else {
      this.setState({ showNoFaceDetected: true });
      return false;
    }
  };

  startCountdown = () => {
    if (this.props.rootStore.faceStore.stopFaceDetection) return;
    if (!this.countdownInterval) {
      this.setState({
        showCountdown: true,
        countdown: 3,
      });
      this.countdownInterval = setInterval(() => {
        this.doCountdown();
      }, 1000);
    }
  };

  resetCountdown = () => {
    if (!!this.countdownInterval) {
      clearTimeout(this.countdownInterval);
      this.countdownInterval = null;
      this.setState({
        showCountdown: false,
        countdown: 3,
      });
    }
  };

  doCountdown = () => {
    const { countdown } = this.state;
    if (countdown === 1) {
      this.finalLook();
      this.resetCountdown();
    } else {
      this.setState({
        countdown: countdown - 1,
      });
    }
  };

  finalLook = () => {
    const {
      rootStore: { faceStore, commonStore },
    } = this.props;
    faceStore.doStopFaceDetection();
    this.setState(
      {
        showCountdown: false,
        doingProcess: false,
        cameraPlaying: false,
      },
      async () => {
        // Do final look after state is changed
        const detectionsWithLandmarks = await faceapi
          .detectSingleFace(
            this.cameraView.canvas,
            new faceapi.SsdMobilenetv1Options()
          )
          .withFaceLandmarks()
          .withFaceDescriptor();

        const result = faceStore.findFace(detectionsWithLandmarks.descriptor);
        const maskResult = ZhuxinMask.detectMask(this.cameraView.canvas);
        this.setState({
          showResult: true,
          faceLabel: result && result.distance < 0.4 ? result.label : "无匹配",
          hasMask: maskResult[0][1] === 0,
        });

        // TODO Reset everything
        setTimeout(() => {
          this.setState({
            faceLabel: null,
            hasMask: false,
            showResult: false,
            cameraPlaying: true,
          });
          commonStore.hideHumanSignUpPage();
        }, 5000);
        setTimeout(() => {
          faceStore.restartFaceDetection();
        }, 8000);
      }
    );
  };

  toggleProcess = () => {
    this.setState({
      doingProcess: !this.state.doingProcess,
    });
  };

  openFaceManagementDrawer = () => {
    this.setState({
      faceManagementDrawerOpen: true,
    });
  };

  closeFaceManagementDrawer = () => {
    this.setState({
      faceManagementDrawerOpen: false,
    });
  };

  render() {
    const {
      ready,
      faceManagementDrawerOpen,
      cameraPlaying,
      countdown,
      showCountdown,
      faceLabel,
      hasMask,
      showResult,
      showFaceNotInBox,
      showNoFaceDetected,
    } = this.state;

    if (!ready) {
      return null;
    }

    return (
      <div
        className="HumanSignup"
        ref={(rootElement) => {
          this.rootElement = rootElement;
        }}
      >
        <CameraView
          ref={(ref) => (this.cameraView = ref)}
          processFrame={this.processFrame}
          processInterval={200}
          playing={cameraPlaying}
        />
        {showResult && (
          <div className="HumanSignup__result">
            歡迎: {faceLabel}
            <br />
            {hasMask ? (
              <span style={{ color: "green" }}>
                非常好!檢測到你有戴口罩，請在訪問中繼續佩戴!
              </span>
            ) : (
              <span style={{ color: "red" }}>
                檢測到你未有戴上口罩，如需繼續訪問，請立刻戴上口罩!
              </span>
            )}
          </div>
        )}
        {showFaceNotInBox && !showNoFaceDetected && (
          <div className="HumanSignup__result">請將整個面部對準虛線框內!</div>
        )}
        {showNoFaceDetected && (
          <div className="HumanSignup__result">未檢測到人臉</div>
        )}
        <div
          className="HumanSignup__frame"
          ref={(detectionBoxElement) => {
            this.detectionBoxElement = detectionBoxElement;
          }}
        >
          {showCountdown && (
            <div className="HumanSignup__countdown">
              <span className="HumanSignup__countdown_number">{countdown}</span>
              秒內開始檢測:
            </div>
          )}
        </div>

        <Fab
          color="primary"
          className="HumanSignup__faceDrawerToggle"
          onClick={this.openFaceManagementDrawer}
        >
          <Icon>face</Icon>
        </Fab>

        <FaceManagementDrawer
          open={faceManagementDrawerOpen}
          onClose={this.closeFaceManagementDrawer}
        />
      </div>
    );
  }
}
