import React, { Component } from "react";
import { PropsWithStore } from "store";
import "./Setting.scss";
import { inject, observer } from "mobx-react";
import { TextField, Button, Box } from "@material-ui/core";

interface Props extends PropsWithStore {
  history?: any;
}
interface State {}
@inject("rootStore")
@observer
export default class Setting extends Component<Props, State> {
  state = {
    deviceId: null,
    deviceSerial: null,
    showWrong: false,
    showError: false,
    comPath: "",

    productKey: "",
    deviceName: "",
    deviceSecret: "",

    ready: false,
  };

  componentDidMount = async () => {
    const {
      rootStore: { configStore },
    } = this.props;
    this.setState({
      deviceId: configStore.deviceId,
      comPath: configStore.comPath,
      productKey: configStore.productKey,
      deviceName: configStore.deviceName,
      deviceSecret: configStore.deviceSecret,
    });
  };

  handleDeviceIdChange = (deviceId) => {
    this.setState({ showWrong: false, showError: false });
    const reg = /^[0-9]*$/;
    if (!reg.test(deviceId)) return;
    this.setState({ deviceId });
  };

  handleDeviceSerialChange = (deviceSerial) => {
    this.setState({ showWrong: false, showError: false });
    this.setState({ deviceSerial });
  };

  handleSaveDeviceInfo = async () => {
    this.setState({ showWrong: false, showError: false });
    const { deviceId, deviceSerial } = this.state;
    const {
      rootStore: { configStore },
    } = this.props;
    await configStore.setDeviceInfo(deviceId, deviceSerial);
    const result = configStore.checkDeviceStatus;
    if (result === "checked") {
      this.setState({
        ready: true,
      });
    } else if (result === "error") {
      this.setState({ showError: true });
    } else {
      this.setState({ showWrong: true });
    }
  };

  handleSaveComPath = async () => {
    const { comPath } = this.state;
    const {
      rootStore: { configStore },
    } = this.props;
    await configStore.setComPath(comPath);
  };

  testConnection = () => {
    const {
      rootStore: { temperatureStore },
    } = this.props;
    temperatureStore.connectAndGetData();
  };

  resetHardware = () => {
    const {
      rootStore: { temperatureStore },
    } = this.props;
    temperatureStore.reset();
  };

  render() {
    const {
      deviceId,
      deviceSerial,
      showError,
      showWrong,
      comPath,
      productKey,
      deviceName,
      deviceSecret,
    } = this.state;
    const {
      rootStore: { temperatureStore, configStore },
    } = this.props;
    return (
      <div className="Setting">
        <div className="header">
          <span className="header_text">设置当前设备的ID</span>
          <br />
          {showWrong && <span className="header_error">错误的设备ID</span>}
          {showError && (
            <span className="header_error">后台出错了，联系开发者吧！</span>
          )}
          <div className="header_inputbox">
            <TextField
              id="outlined-basic"
              label="设备ID"
              variant="filled"
              className="header_inputbox_textfield"
              onChange={(event) =>
                this.handleDeviceIdChange(event?.target.value)
              }
              type="number"
              value={deviceId || ""}
            />
            <TextField
              id="outlined-basic"
              label="设备序列号"
              variant="filled"
              className="header_inputbox_textfield"
              onChange={(event) =>
                this.handleDeviceSerialChange(event?.target.value)
              }
              type="text"
              value={deviceSerial || ""}
            />
          </div>
          <div className="header_button">
            <Button
              onClick={this.handleSaveDeviceInfo}
              variant="contained"
              color="primary"
              className="header_button_save"
            >
              保存
            </Button>
          </div>

          <br />
          <br />
          <span className="header_text">设置阿里云相关数据</span>
          <br />
          <div className="header_inputbox">
            <TextField
              id="outlined-basic"
              label="产品KEY"
              variant="filled"
              className="header_inputbox_textfield"
              onChange={(event) =>
                this.setState({
                  productKey: event?.target.value,
                })
              }
              type="text"
              value={productKey || ""}
            />
            <TextField
              id="outlined-basic"
              label="设备名称"
              variant="filled"
              className="header_inputbox_textfield"
              onChange={(event) =>
                this.setState({
                  deviceName: event?.target.value,
                })
              }
              type="text"
              value={deviceName || ""}
            />
            <TextField
              id="outlined-basic"
              label="设备密钥"
              variant="filled"
              className="header_inputbox_textfield"
              onChange={(event) =>
                this.setState({
                  deviceSecret: event?.target.value,
                })
              }
              type="text"
              value={deviceSecret || ""}
            />
          </div>
          <div className="header_button">
            <Button
              onClick={() => {
                const { productKey, deviceName, deviceSecret } = this.state;
                configStore.setAliyunDevice({
                  productKey,
                  deviceName,
                  deviceSecret,
                });
              }}
              variant="contained"
              color="primary"
              className="header_button_save"
            >
              保存
            </Button>

            <Button
              disabled={!this.state.ready}
              onClick={() => {
                if (this.state.ready) {
                  this.props.history.push("/form");
                }
              }}
              variant="contained"
              color="primary"
              className="header_button_save"
            >
              完成设置
            </Button>
          </div>

          {!!temperatureStore.serialPort && (
            <>
              <Box className="header_text" marginTop={4}>
                Set COM Path For Temperature Device
              </Box>
              <div className="header_inputbox">
                <TextField
                  id="outlined-basic"
                  label="COM Path"
                  variant="filled"
                  style={{ width: "100%" }}
                  className="header_inputbox_textfield"
                  onChange={(event) =>
                    this.setState({
                      comPath: event?.target.value,
                    })
                  }
                  type="string"
                  value={comPath}
                />
              </div>
              <div className="header_button">
                <Button
                  onClick={this.handleSaveComPath}
                  variant="contained"
                  color="primary"
                  className="header_button_save"
                >
                  SAVE
                </Button>

                <Button
                  onClick={this.testConnection}
                  variant="contained"
                  color="secondary"
                  className="header_button_save"
                >
                  Test Connection
                </Button>

                <Button
                  onClick={this.resetHardware}
                  variant="contained"
                  color="secondary"
                  className="header_button_save"
                >
                  Reset Hardware
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
