import React, { Component } from "react";
import { Button, Box } from "@material-ui/core";
import "components/CommonClass/common.scss";
import { inject, observer } from "mobx-react";
import { RouteComponentProps } from "react-router-dom";
import { PropsWithStore } from "store";
import "./TermsPage.scss";

interface Props extends PropsWithStore {}
interface State {
  rejected: boolean;
}

@inject("rootStore")
@observer
export default class TermsPage extends Component<
  Props & RouteComponentProps,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      rejected: false,
    };
  }

  render() {
    const { rejected } = this.state;
    const {
      rootStore: { configStore },
    } = this.props;
    return (
      <div>
        <div className="middle">
          <h1>条款页面</h1>
          {!rejected && (
            <>
              <div
                className="toc"
                dangerouslySetInnerHTML={{
                  __html: configStore?.userConfig?.toc,
                }}
              ></div>

              <div className="two_buttons">
                <Button
                  onClick={() => {
                    this.props.history.push("/form");
                  }}
                  className="button"
                  variant="contained"
                  color="primary"
                >
                  接受
                </Button>
                <Button
                  onClick={() => {
                    this.setState({
                      rejected: true,
                    });
                  }}
                  className="button outlined_button"
                  variant="outlined"
                  color="primary"
                >
                  拒绝
                </Button>
              </div>
            </>
          )}

          {rejected && (
            <Box display="flex" flexDirection="column">
              <p>请联系前台进一步处理</p>

              <Button
                onClick={() => {
                  this.props.history.push("/");
                }}
                className="button"
                variant="contained"
                color="primary"
              >
                退出
              </Button>
            </Box>
          )}
        </div>
      </div>
    );
  }
}
