import configStore from "store/configStore";
import { observable } from "mobx";

export class TemperatureStore {
  @observable serialPort: any;
  connection: any;

  longConnection: any;

  @observable isTemperatureReady: boolean = false;
  @observable currentTemperature: number = 0;

  init = () => {
    if (!!(window as any).nodeRequire) {
      this.serialPort = (window as any)
        .nodeRequire("electron")
        .remote.require("serialport");

      // this.longConnection = new this.serialPort(configStore.comPath, {
      //   baudRate: 115200,
      // });
      // const buffer = new Buffer(8);
      // buffer[0] = 0xa5;
      // buffer[1] = 0x55;
      // buffer[2] = 0x03;
      // buffer[3] = 0x02;
      // buffer[3] = 0xff;
      // this.longConnection.write(buffer);

      // this.longConnection.on("data", (data) => {
      //   console.log(data);
      //   console.log("Long Range Temp data", (data[2], data[3] * 256) / 100);
      //   console.log("Long Range Distance data", (data[4], data[5] * 256) / 100);
      //   console.log("Long Range Configured properly?", data[6] === 0);
      // });
    } else {
      console.log("No window require");
      this.serialPort = null;
    }
  };

  connectAndGetData = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (!!this.serialPort) {
        // console.log(this.serialPort);
        console.log("Connecting", configStore.comPath);
        if (this.connection) {
          this.connection.close();
        }

        this.connection = new this.serialPort(configStore.comPath, {
          baudRate: 115200,
        });
        // console.log(this.connection);
        const buffer = new Buffer(8);
        buffer[0] = 0xa5;
        buffer[1] = 0x55;
        buffer[2] = 0x04;
        buffer[3] = 0xfe;
        this.connection.write(buffer);
        this.connection.once("data", (data) => {
          console.log(data);
          console.log("Temp data", (data[2] + data[3] * 256) / 100);
          console.log("Distance data", (data[4] + data[5] * 256) / 100);
          console.log("Configured properly?", data[6] === 0);
          this.connection.close();
          this.connection = null;
          resolve({
            temperature: (data[2] + data[3] * 256) / 100,
            distance: (data[4] + data[5] * 256) / 100,
            isConfigured: data[6] === 0,
          });
        });
        this.connection.once("error", function (err) {
          console.log("Error: ", err.message);
          reject();
        });
      } else {
        reject();
      }
    });
  };

  reset = () => {
    const connection = new this.serialPort(configStore.comPath, {
      baudRate: 115200,
    });
    // console.log(this.connection);
    const buffer = new Buffer(8);
    buffer[0] = 0xa5;
    buffer[1] = 0x55;
    buffer[2] = 0x05;
    buffer[3] = 0xff;

    connection.write(buffer);
    connection.once("data", (data) => {
      console.log("Doing reset");
      connection.close();
    });
  };
}

export default new TemperatureStore();
