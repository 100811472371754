import socketIOClient from "socket.io-client";
import { observable } from "mobx";

let bufferSize = 2048;

const constraints = {
  audio: true,
  video: false,
};

export class SpeechStore {
  @observable currentText = "";
  @observable recognitionDone = false;
  socket: any;
  globalStream: any;
  streamStreaming: boolean = false;
  AudioContext: any;
  context: any;
  processor: any;
  input: any;

  constructor() {
    window.onbeforeunload = function () {
      if (this.streamStreaming) {
        this.socket.emit("endGoogleCloudStream", "");
        this.socket.eimit("endDialogFlowStream", "");
      }
    };
  }

  microphoneProcess(e) {
    var left = e.inputBuffer.getChannelData(0);
    // var left16 = convertFloat32ToInt16(left); // old 32 to 16 function
    var left16 = downsampleBuffer(left, 44100, 16000);
    this.socket.emit("binaryData", left16);
  }

  getSocket = () => {
    return this.socket;
  };

  init = () => {
    this.socket = socketIOClient("wss://api.zhuxin.ai", {
      transports: ["websocket"],
    });

    this.socket.on("connect", (data) => {
      this.socket.emit("join", "Server Connected to Client");
    });

    this.socket.on("messages", (data) => {
      console.log(data);
    });
  };

  startRecording = () => {
    this.socket.emit("startGoogleCloudStream", {});
    this.streamStreaming = true;
    this.AudioContext =
      window.AudioContext || (window as any).webkitAudioContext;
    this.context = new this.AudioContext({
      // if Non-interactive, use 'playback' or 'balanced' // https://developer.mozilla.org/en-US/docs/Web/API/AudioContextLatencyCategory
      latencyHint: "interactive",
    });
    this.processor = this.context.createScriptProcessor(bufferSize, 1, 1);
    this.processor.connect(this.context.destination);
    this.context.resume();

    var handleSuccess = (stream) => {
      this.globalStream = stream;
      this.input = this.context.createMediaStreamSource(stream);
      this.input.connect(this.processor);
      this.processor.onaudioprocess = (e) => {
        this.microphoneProcess(e);
      };
    };

    navigator.mediaDevices.getUserMedia(constraints).then(handleSuccess);
  };

  waitForSpeech = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      this.startRecording();
      this.socket.on("speechData", (data) => {
        let speech = data.results[0].alternatives[0].transcript;
        let dataFinal = undefined || data.results[0].isFinal;
        if (dataFinal === false) {
        } else {
          console.log("Google Speech sent 'final' Sentence.");
          speech += ".";
          // voiceStore.recognitionDone(speech);
          this.currentText = speech;
          this.recognitionDone = true;
          // console.log("Done", speech);
          this.stopRecording();
          this.socket.off("speechData");
          resolve(speech as string);
        }
        // console.log(speech);
        this.currentText = speech;
      });
    });
  };

  stopRecording = () => {
    if (!this.input || !this.processor || !this.context) {
      return;
    }

    this.socket.emit("endGoogleCloudStream", "");
    let track = this.globalStream.getTracks()[0];
    track.stop();

    this.input.disconnect(this.processor);
    this.processor.disconnect(this.context.destination);
    return this.context.close().then(() => {
      this.input = null;
      this.processor = null;
      this.context = null;
      this.AudioContext = null;
    });
  };
}

export default new SpeechStore();

const downsampleBuffer = function (buffer, sampleRate, outSampleRate) {
  if (outSampleRate === sampleRate) {
    return buffer;
  }
  if (outSampleRate > sampleRate) {
    throw new Error(
      "downsampling rate show be smaller than original sample rate"
    );
  }
  var sampleRateRatio = sampleRate / outSampleRate;
  var newLength = Math.round(buffer.length / sampleRateRatio);
  var result = new Int16Array(newLength);
  var offsetResult = 0;
  var offsetBuffer = 0;
  while (offsetResult < result.length) {
    var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
    var accum = 0,
      count = 0;
    for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
      accum += buffer[i];
      count++;
    }

    result[offsetResult] = Math.min(1, accum / count) * 0x7fff;
    offsetResult++;
    offsetBuffer = nextOffsetBuffer;
  }
  return result.buffer;
};
