import React, { Component } from "react";
import CameraView from "components/CameraView";
import { PropsWithStore } from "store";
import { ZhuxinMask } from "tf/mask";
import "./style.scss";
import "components/CommonClass/common.scss";
import { inject, observer } from "mobx-react";
import * as tf from "@tensorflow/tfjs";
import { Box } from "@material-ui/core";
import Temperature from "./Temperature";

interface Props extends PropsWithStore {
  history?: any;
  onNext?: () => void;
}
interface State {}
@inject("rootStore")
@observer
export default class FaceMask extends Component<Props, State> {
  state = {
    hasHuman: false,
    hasMask: false,
    hasFaceAvailable: false,
    detectedMaskDone: false,
    detectionTemperatureDone: false,
    ready: false,
    temperatureReady: false,
  };

  componentDidMount = async () => {
    // await Promise.all([ZhuxinMask.loadModel()]);
    setTimeout(() => {
      this.setState({
        ready: true,
      });
    }, 500);

    if (window.location.search.includes("react-native")) {
      document.addEventListener("message", this.processTensorFromDocument);
      window.addEventListener("message", this.processTensorFromDocument);
    }
  };

  processFrame = (canvas: HTMLCanvasElement) => {
    const {
      rootStore: { formStore },
    } = this.props;
    const { hasMask } = this.state;
    const frameSnapShotBase64 = canvas.toDataURL("image/jpeg", 0.5);
    const maskResult = ZhuxinMask.detectMask(canvas);

    const newHasMask = maskResult && maskResult[0] && maskResult[0][1] === 0;

    if (!hasMask && !!newHasMask) {
      formStore.setMaskImage(frameSnapShotBase64);
    }

    this.setState({
      hasHuman: maskResult.length > 0,
      hasFaceAvailable:
        maskResult && maskResult[0] && this.faceAvailable(maskResult[0][0]),
      hasMask: newHasMask,
      detectedMaskDone: true,
    });
  };

  processTensorFromDocument = (event: any) => {
    alert("Got Message From Native");
    const data: any = event.data;
    if (!!data && data.type === "camera-frame") {
      const tensorString: string = data.tensor;
      this.processFrame(tf.tensor(JSON.parse(tensorString)) as any);
    }
  };

  faceAvailable = (faceZone) => {
    const width = faceZone[2] - faceZone[0];
    return width > 200;
  };

  componentWillUnmount = () => {
    document.removeEventListener("message", this.processTensorFromDocument);
    window.removeEventListener("message", this.processTensorFromDocument);
  };

  onTemperatureDone = () => {
    this.setState({
      temperatureReady: true,
    });
  };

  componentDidUpdate = () => {
    if (this.state.hasMask && this.state.temperatureReady) {
      this.props.onNext && this.props.onNext();
    }
  };

  render() {
    const { hasMask, detectedMaskDone, ready } = this.state;

    if (!ready) {
      return <div className="middle">正在初始化口罩识别</div>;
    }

    return (
      <>
        <div className="middle">
          <Temperature onNext={this.onTemperatureDone} />
          <Box width={640} height={360}>
            <CameraView
              processFrame={this.processFrame}
              processInterval={1000}
            />
          </Box>
          <p>
            {!detectedMaskDone && "正在检查口罩状态"}
            {!hasMask && detectedMaskDone && "请佩戴口罩"}
            {hasMask && detectedMaskDone && "已佩戴口罩"}
          </p>
        </div>
      </>
    );
  }
}
