import { observable } from "mobx";

export class FormStore {
  @observable wearMask: boolean;
  @observable startForm: boolean;
  @observable formFinished: boolean;
  @observable questions: Array<any>;
  @observable temperature: number = 0;
  @observable healthCode: string = "";
  @observable faceImage: string = "";
  @observable maskImage: string = "";

  constructor() {
    this.init();
  }

  async init() {
    this.reset();
    this.wearMask = false;
    this.startForm = false;
    this.formFinished = false;
  }

  reset() {
    this.temperature = 0;
    this.healthCode = "";
    this.faceImage = "";
    this.maskImage = ""
  }

  setWearMask = () => {
    this.wearMask = true;
  };

  setNotWearMask = () => {
    this.wearMask = false;
  };

  setFormStart = () => {
    this.startForm = true;
    this.formFinished = false;
  };

  setFormEnd = () => {
    this.startForm = false;
  };

  setFormFinish = () => {
    this.formFinished = true;
    this.startForm = false;
  };

  setQuestions = (questions: any) => {
    this.questions = questions;
  };

  setTemperature = (temp: number) => {
    this.temperature = temp;
  };

  setHealthCode = (healthCode: string) => {
    this.healthCode = healthCode;
  };

  setFaceImage = (image: string) => {
    this.faceImage = image;
  };

  setMaskImage = (image: string) => {
    this.maskImage = image;
  };
}

export default new FormStore();
