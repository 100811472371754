class ZhuxinCameraClass {
  currentStream: MediaStream = null;

  initToCanvas = async (): Promise<MediaStream> => {
    try {
      if (this.currentStream) {
        return this.currentStream;
      }
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { width: 1280, height: 720 },
      });
      this.currentStream = mediaStream;
      return mediaStream;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
}

export const ZhuxinCamera = new ZhuxinCameraClass();
