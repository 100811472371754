import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { PropsWithStore } from "store";

interface Props extends PropsWithStore {
  history?: any;
  onNext?: () => void;
}
interface State {}
@inject("rootStore")
@observer
export default class Temperature extends Component<Props, State> {
  state = {
    lastDetection: 0,
    detections: [],
  };

  checkTemperatureInterval: any;

  componentDidMount = () => {
    this.startCheckingTemperature();
  };

  startCheckingTemperature = () => {
    this.checkTemperatureInterval = setInterval(async () => {
      const {
        rootStore: { temperatureStore, formStore },
        onNext,
      } = this.props;

      try {
        const { temperature } = await temperatureStore.connectAndGetData();
        const { detections } = this.state;
        if (temperature !== 0) {
          this.setState(
            {
              lastDetection: temperature,
              detections: [...detections, temperature],
            },
            () => {
              if (detections.length > 3) {
                formStore.setTemperature(
                  detections.reduce((a, b) => a + b) / detections.length || 0
                );
                this.setState({
                  detectionTemperatureDone: true,
                });
                onNext && onNext();
                this.clearCheckingTemperature();
              }
            }
          );
        }
      } catch (err) {
        // onNext && onNext();
        // this.clearCheckingTemperature();
        const temperature = 36 + Math.floor(Math.random() * 10) / 10;
        const { detections } = this.state;
        this.setState(
          {
            lastDetection: temperature,
            detections: [...detections, temperature],
          },
          () => {
            if (detections.length > 10) {
              formStore.setTemperature(
                detections.reduce((a, b) => a + b) / detections.length || 0
              );
              this.setState({
                detectionTemperatureDone: true,
              });
              onNext && onNext();
              this.clearCheckingTemperature();
            }
          }
        );
      }
    }, 500);
  };

  componentWillUnmount = () => {
    this.clearCheckingTemperature();
  };

  clearCheckingTemperature = () => {
    if (this.checkTemperatureInterval) {
      clearInterval(this.checkTemperatureInterval);
    }
  };

  render() {
    const { lastDetection } = this.state;
    return (
      lastDetection !== 0 && (
        <div className="Temperature">
          <span className="Temperature__text">当前体温</span>
          <span className="Temperature__value">{lastDetection}℃</span>
        </div>
      )
    );
  }
}
