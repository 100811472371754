import React, { Component } from "react";
import { ZhuxinCamera } from "../../util/camera";
import "./CameraView.scss";

interface Props {
  processFrame?: (
    canvas: HTMLCanvasElement,
    resultCanvas: HTMLCanvasElement
  ) => void;
  processInterval?: number;
  playing?: boolean;
  backgroundMode?: boolean;
}
interface State {}

export default class CameraView extends Component<Props, State> {
  state = {};

  canvasInterval: any;
  frameProcessInterval: any;
  initTimeout: any;

  video: HTMLVideoElement = document.createElement("video");
  canvas: HTMLCanvasElement = document.createElement("canvas");
  resultCanvas: HTMLCanvasElement = document.createElement("canvas");

  componentDidMount = async () => {
    if (!!window.navigator && !!window.navigator.mediaDevices) {
      const mediaStream = await ZhuxinCamera.initToCanvas();
      this.initTimeout = setTimeout(() => {
        this.video.srcObject = mediaStream;
        this.video.play();
        this.initCanvas();
        this.initFrameProcess();
      }, 200);
      window.addEventListener("resize", this.onWindowResize);
    }
  };

  handleCameraFrame = (event) => {
    const image = new Image();
    image.onload = () => {
      const context = this.canvas.getContext("2d") as CanvasRenderingContext2D;
      context.drawImage(image, 0, 0);
    };
    image.src = event.data;
  };

  componentWillUnmount() {
    clearInterval(this.canvasInterval);
    clearInterval(this.frameProcessInterval);
    clearTimeout(this.initTimeout);
    window.removeEventListener("resize", this.onWindowResize);
  }

  initCanvas = () => {
    this.canvasInterval = setInterval(() => {
      const { playing = true } = this.props;
      if (playing) {
        const context = this.canvas.getContext(
          "2d"
        ) as CanvasRenderingContext2D;
        context.drawImage(this.video, 0, 0, 1280, 720);
      }
    }, 20);
  };

  initFrameProcess = () => {
    const { processFrame, processInterval = 100 } = this.props;
    this.frameProcessInterval = setInterval(() => {
      processFrame && processFrame(this.canvas, this.resultCanvas);
    }, processInterval);
  };

  onWindowResize = () => {};

  render() {
    const { backgroundMode = false } = this.props;
    return (
      <div
        className={`CameraView ${
          !!backgroundMode ? "CameraView--background" : ""
        }`}
      >
        <video ref={(ref: HTMLVideoElement) => (this.video = ref)} />
        <canvas
          width={1280}
          height={720}
          ref={(ref: HTMLCanvasElement) => (this.canvas = ref)}
        />

        <canvas
          width={1280}
          height={720}
          ref={(ref: HTMLCanvasElement) => (this.resultCanvas = ref)}
        />
      </div>
    );
  }
}
