import * as faceapi from "face-api.js";
import shortid from "shortid";
import { observable } from "mobx";
import localforage from "localforage";
import { api } from "config/api";

export interface IZhuxinFaceImage {
  id: string;
  imageBase64: string;
  faceData: Float32Array;
}

export interface IZhuxinFace {
  id: string;
  name: string;
  faceImages: IZhuxinFaceImage[];
}

export class FaceStore {
  @observable faces: IZhuxinFace[] = [];
  @observable stopFaceDetection: boolean;

  @observable cloudFaces: any[] = [];

  constructor() {
    this.init();

    this.getCloudFaces();
  }

  async init() {
    const faces = await localforage.getItem("ZHUXIN_FACES");
    this.stopFaceDetection = false;
    // console.log("ZHUXIN_FACES", faces);

    if (!!faces) {
      const rawFaces: IZhuxinFace[] = JSON.parse(faces as any);
      rawFaces.forEach((face) => {
        face.faceImages.forEach((image) => {
          image.faceData = Float32Array.from(
            Object.keys(image.faceData).map((key) => image.faceData[key]) as any
          );
        });
      });
      // console.log(rawFaces);
      this.faces = rawFaces;
    } else {
      this.faces = [];
    }
  }

  findFace = (faceDescriptor: any) => {
    if (this.faces.length < 1) return null;
    const faceMatcher = new faceapi.FaceMatcher(
      this.faces.map(
        (face) =>
          new faceapi.LabeledFaceDescriptors(
            face.name,
            face.faceImages.map(({ faceData }) => faceData)
          )
      )
    );
    return faceMatcher.findBestMatch(faceDescriptor);
  };

  addNewImageToFace = (face: IZhuxinFace, image: IZhuxinFaceImage) => {
    const addToFace = this.faces.find((_face) => _face.id === face.id);
    addToFace.faceImages.push({ ...image, id: shortid.generate() });
  };

  addNewFace = (): IZhuxinFace => {
    const newFace = {
      name: "New Name",
      faceImages: [],
      id: shortid.generate(),
    };
    this.faces.push(newFace);
    this.saveFaces();
    return newFace;
  };

  deleteFace = (deletingFace) => () => {
    this.faces = this.faces.filter((face) => face !== deletingFace);
    this.saveFaces();
  };

  updateFace = (face) => {
    const index = this.faces.findIndex((_face) => face.id === _face.id);
    this.faces[index] = face;
    this.saveFaces();
  };

  saveFaces = () => {
    localforage.setItem("ZHUXIN_FACES", JSON.stringify(this.faces));
  };

  doStopFaceDetection = () => {
    this.stopFaceDetection = true;
  };

  restartFaceDetection = () => {
    this.stopFaceDetection = false;
  };

  getCloudFaces = async () => {
    try {
      const { data } = await api.get("/visitor/today");
      this.cloudFaces = data;
    } finally {
      console.log(this.cloudFaces);
    }
  };
}

export default new FaceStore();
