import React, { Component } from "react";
import { Box, Button } from "@material-ui/core";
import CameraView from "components/CameraView";
import { detectHealthCodeInCanvas } from "tf/qr";
import { RouteComponentProps } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { PropsWithStore } from "store";

interface Props extends PropsWithStore {}
interface State {}

@inject("rootStore")
@observer
export default class Prefill extends Component<
  Props & RouteComponentProps,
  State
> {
  state = {};

  processFrame = async (canvas: HTMLCanvasElement) => {
    try {
      const code = detectHealthCodeInCanvas(canvas);
      if (code && code.data && code.data !== "") {
        const {
          rootStore: { configStore },
        } = this.props;
        const steps = configStore.userConfig.questions;
        let visitorAnswer = [];
        JSON.parse(code.data).forEach(
          (answer) => (visitorAnswer[answer.label] = answer.answer)
        );
        const questions = steps.map((step) => {
          return {
            ...step,
            answer: visitorAnswer[step.label]
              ? visitorAnswer[step.label]
              : step.type === "multiple-choice"
              ? -1
              : "",
          };
        });
        this.props.rootStore?.formStore?.setQuestions(questions);
        this.props.history.push("/body-check");
      }
    } finally {
    }
  };

  gotoForm = () => {
    this.props.history.push("/form");
  };

  render() {
    return (
      <div>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          width="100%"
          color="white"
        >
          <Box marginY={2}>
            <h1>请出示预填二维码</h1>
          </Box>
          <Box width={500} height={500} position={"relative"}>
            <CameraView
              processFrame={this.processFrame}
              processInterval={500}
            />
            <div className="mask-container">
              <div className="mask-container-square"></div>
            </div>
          </Box>
          <Box marginY={2}>
            <h1>或</h1>
          </Box>
          <Box>
            <Button
              style={{ fontSize: 30 }}
              variant="contained"
              color="primary"
              onClick={this.gotoForm}
            >
              现场填写
            </Button>
          </Box>
        </Box>
      </div>
    );
  }
}
