import React, { Component } from "react";
import CameraView from "components/CameraView";
import { PropsWithStore } from "store";
import "./style.scss";
import "components/CommonClass/common.scss";
import { inject, observer } from "mobx-react";
import { Box } from "@material-ui/core";
import { detectHealthCodeInCanvas, isHealthCodeValid } from "tf/qr";

interface Props extends PropsWithStore {
  history?: any;
  onNext?: () => void;
}

interface State {
  foundHealthCode: string;
  foundHealthCodeDone: boolean;
  healthCodeValid: boolean;
  ready: boolean;
}

@inject("rootStore")
@observer
export default class HealthCode extends Component<Props, State> {
  state = {
    foundHealthCode: "",
    foundHealthCodeDone: false,
    healthCodeValid: false,
    ready: true,
  };

  componentDidMount = async () => {};

  processFrame = async (canvas: HTMLCanvasElement) => {
    const { onNext } = this.props;
    const { foundHealthCodeDone, healthCodeValid } = this.state;
    if (foundHealthCodeDone && healthCodeValid) {
      return;
    }
    try {
      const code = detectHealthCodeInCanvas(canvas);
      if (code && code.data && code.data !== "") {
        const _healthCodeValid = isHealthCodeValid(code.data);
        this.setState({
          healthCodeValid: _healthCodeValid,
          foundHealthCodeDone: true,
        });

        if (_healthCodeValid) {
          setTimeout(() => {
            onNext();
          }, 2000);
        }
      }
    } finally {
    }
  };

  render() {
    const { ready, foundHealthCodeDone, healthCodeValid } = this.state;

    if (!ready) {
      return <div className="middle">初始化中，我们将要比对您的访客身份。</div>;
    }

    return (
      <>
        <div className="middle">
          <Box width={640} height={360} position={"relative"}>
            <CameraView
              processFrame={this.processFrame}
              processInterval={1000}
            />
            <div className="mask-container">
              <div className="mask-container-square"></div>
            </div>
          </Box>
          <p>
            {!foundHealthCodeDone
              ? "请出示您的绿码"
              : healthCodeValid
              ? `已识别到绿码`
              : `识别到的不是绿码，请重新出示`}
          </p>
        </div>
      </>
    );
  }
}
