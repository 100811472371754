import { observable } from "mobx";

export class CommonStore {
  @observable showHumanSignUp: boolean;
  @observable showFormView: boolean;

  constructor() {
    this.init();
  }

  async init() {
    this.showHumanSignUp = true;
    this.showFormView = false;
  }

  hideHumanSignUpPage = () => {
    this.showHumanSignUp = false;
    this.showFormView = true;
  };

  showHumanSignUpPage = () => {
    this.showHumanSignUp = true;
  };

  hideFormViewPage = () => {
    this.showFormView = false;
    this.showHumanSignUp = true;
  };

  showFormViewPage = () => {
    this.showFormView = true;
  };
}


export default new CommonStore();
