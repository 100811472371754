import React, { Component } from "react";
import {
  Dialog,
  Box,
  TextField,
  Button,
  Icon,
  IconButton,
} from "@material-ui/core";
import { PropsWithStore } from "../../../../store";
import { IZhuxinFace } from "../../../../store/faceStore";
import CameraView from "../../../CameraView";
import * as faceapi from "face-api.js";
import { inject, observer } from "mobx-react";
import shortid from "shortid";

import "./FaceManagementDialog.scss";

interface Props extends PropsWithStore {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  face: IZhuxinFace;
}
interface State {
  face: IZhuxinFace;
  currentFace: any;
  captured: boolean;
}

@inject("rootStore")
@observer
export default class FaceManagementDialog extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      currentFace: null,
      face: null,
      captured: false,
    };
  }

  componentDidMount = () => {
    const { face } = this.props;
    this.setState({
      face,
    });
  };

  onSave = () => {
    const {
      rootStore: { faceStore },
      onClose,
    } = this.props;
    const { face } = this.state;
    faceStore.updateFace(face);
    onClose();
  };

  captureFace = () => {
    this.setState({
      captured: true,
    });
  };

  recaptureFace = () => {
    this.setState({
      captured: false,
    });
  };

  deleteFaceImage = (image) => () => {
    const { face } = this.state;
    const newFaceImages = face.faceImages.filter(
      (_image) => image.id !== _image.id
    );
    this.setState({
      face: {
        ...face,
        faceImages: newFaceImages,
      },
    });
  };

  confirmFace = () => {
    const { currentFace, face } = this.state;
    this.setState({
      face: {
        ...face,
        faceImages: [
          ...face.faceImages,
          {
            id: shortid.generate(),
            ...currentFace,
          },
        ],
      },
    });
    this.setState({
      captured: false,
    });
  };

  processFrame = async (
    canvas: HTMLCanvasElement,
    resultCanvas: HTMLCanvasElement
  ) => {
    const { captured } = this.state;
    if (!captured) {
      const detectionsWithLandmarks = await faceapi
        .detectSingleFace(canvas, new faceapi.SsdMobilenetv1Options())
        .withFaceLandmarks()
        .withFaceDescriptor();

      this.setState({
        currentFace: !!detectionsWithLandmarks
          ? {
              faceData: detectionsWithLandmarks.descriptor,
              imageBase64: canvas.toDataURL(),
            }
          : null,
      });

      const ctx = resultCanvas.getContext("2d");
      ctx.clearRect(0, 0, resultCanvas.width, resultCanvas.height);
      try {
        faceapi.draw.drawFaceLandmarks(resultCanvas, detectionsWithLandmarks);
      } catch (err) {}
    }
  };

  onChangeName = (event) => {
    const { face } = this.state;
    this.setState({
      face: {
        ...face,
        name: event.target.value,
      },
    });
  };

  render() {
    const { open, onClose } = this.props;
    const { face, currentFace, captured } = this.state;

    if (!face) {
      return null;
    }

    return (
      <Dialog
        container={document.body}
        open={open}
        onClose={onClose}
        maxWidth="xl"
      >
        <Box padding={3}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Button color="primary" variant="contained" onClick={this.onSave}>
              Save
            </Button>
          </Box>
          <TextField
            onChange={this.onChangeName}
            label="Face Name"
            value={face.name}
          />
          <h4>Face Images</h4>
          <Box
            display="flex"
            flexDirection="row"
            className="FaceManagementDialog__face"
          >
            {face.faceImages.length > 0 ? (
              face.faceImages.map((image) => (
                <Box className="FaceManagementDialog__item" key={image.id}>
                  <IconButton
                    onClick={this.deleteFaceImage(image)}
                    className="FaceManagementDialog__item-close"
                  >
                    <Icon>close</Icon>
                  </IconButton>
                  <img
                    className="FaceManagementDialog__face"
                    src={image.imageBase64}
                    alt="face"
                  />
                </Box>
              ))
            ) : (
              <p>No images</p>
            )}
          </Box>
          <Box marginY={2} width={640} height={360}>
            <CameraView playing={!captured} processFrame={this.processFrame} />
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            {captured ? (
              <>
                <Button variant="contained" onClick={this.recaptureFace}>
                  Cancel
                </Button>
                <Button
                  disabled={!currentFace}
                  color="primary"
                  variant="contained"
                  onClick={this.confirmFace}
                >
                  Confirm
                </Button>
              </>
            ) : (
              <Button
                disabled={!currentFace}
                color="primary"
                variant="contained"
                onClick={this.captureFace}
              >
                Capture
              </Button>
            )}
          </Box>
        </Box>
      </Dialog>
    );
  }
}
