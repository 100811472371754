import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { PropsWithStore } from "store";
import { inject, observer } from "mobx-react";
import LoadingPage from "pages/LoadingPage";
import HumanSignupApp from "pages/HumanSignUpApp";
import FormDemo from "pages/FormDemo";
import Setting from "pages/Setting";
import SuccessDoneForm from "pages/SuccessDoneForm";
import WelcomePage from "pages/WelcomePage";
import TermsPage from "pages/TermsPage";
import BodyCheck from "pages/BodyCheck";
import HelpButton from "components/HelpButton";
import Prefill from "pages/Prefill";

interface Props extends PropsWithStore {
  history?: any;
}
interface State {}
@inject("rootStore")
@observer
export default class Routes extends Component<Props, State> {
  componentDidMount = () => {
    const {
      rootStore: { configStore },
    } = this.props;
    if (!window.location.hash.includes("welcome")) {
      window.location.href = "/#/";
    }
    setTimeout(() => {
      configStore.init();
    }, 500);
  };

  render() {
    const {
      rootStore: {
        configStore: { configLoaded },
      },
    } = this.props;
    return (
      <div className="App">
        {configLoaded ? (
          <>
            <Switch>
              <Route
                component={HumanSignupApp}
                exact
                path="/human-sign-up"
              ></Route>
              <Route component={WelcomePage} exact path="/welcome"></Route>
              <Route component={TermsPage} exact path="/terms"></Route>
              <Route component={FormDemo} exact path="/form"></Route>
              <Route component={Setting} exact path="/setting"></Route>
              <Route component={BodyCheck} exact path="/body-check"></Route>
              <Route component={SuccessDoneForm} exact path="/success"></Route>
              <Route component={Prefill} exact path="/prefill"></Route>
              <Redirect to="/welcome" />
            </Switch>

            <HelpButton />
          </>
        ) : (
          <LoadingPage />
        )}
      </div>
    );
  }
}
