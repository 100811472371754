import React, { Component } from "react";
import * as faceapi from "face-api.js";
import { ZhuxinMask } from "tf/mask";
import CameraView from "components/CameraView";

interface Props {
  setFaceDetected: (faceDetected: boolean) => void;
}

interface State {
  cameraPlaying: boolean;
  processInterval: number;
}

export default class FaceDetected extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      cameraPlaying: true,
      processInterval: 1000,
    };
  }

  cameraView: CameraView;
  processFrame = async (
    canvas: HTMLCanvasElement
  ) => {
    try {
      const detections = await faceapi.detectSingleFace(
        canvas,
        new faceapi.TinyFaceDetectorOptions()
      );
      const maskResult = ZhuxinMask.detectMask(canvas);
      if (detections || maskResult.length > 0) {
        this.props.setFaceDetected(true);
        this.setState({ processInterval: 5000 });
      } else {
        this.props.setFaceDetected(false);
        this.setState({ processInterval: 1000 });
      }
    } catch (err) {}
  };

  render() {
    const { cameraPlaying, processInterval } = this.state;
    return (
      <div>
        <CameraView
          ref={(ref) => (this.cameraView = ref)}
          processFrame={this.processFrame}
          processInterval={processInterval}
          playing={cameraPlaying}
        />
      </div>
    );
  }
}
