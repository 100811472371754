import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { PropsWithStore } from "../../store";
import { RouteComponentProps } from "react-router-dom";

import FormView from "../../components/FormView";
import HumanSignup from "../../components/HumanSignup";

interface Props extends PropsWithStore {}
interface State {}

@inject("rootStore")
@observer
export default class HumanSignupApp extends Component<
  Props & RouteComponentProps,
  State
> {
  state = {};
  render() {
    const {
      rootStore: {
        commonStore: { showHumanSignUp, showFormView },
      },
    } = this.props;
    if (showHumanSignUp) return <HumanSignup />;
    if (showFormView) return <FormView />;
    return null;
  }
}
