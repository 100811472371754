import React, { Component } from "react";
import {
  Drawer,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Button,
  IconButton,
  Icon,
} from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { PropsWithStore } from "../../../store";
import "./FaceManagerDrawer.scss";
import FaceManagementDialog from "./FaceManagementDialog";
import { IZhuxinFace } from "../../../store/faceStore";

interface Props extends PropsWithStore {
  open: boolean;
  onClose: () => void;
}
interface State {
  editingFace: IZhuxinFace;
}

@inject("rootStore")
@observer
export default class FaceManagementDrawer extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      editingFace: null,
    };
  }

  addFace = () => {
    const {
      rootStore: { faceStore },
    } = this.props;
    const face = faceStore.addNewFace();
    this.setState({
      editingFace: face,
    });
  };

  editFace = (face) => () => {
    this.setState({
      editingFace: face,
    });
  };

  onClose = () => {
    this.setState({
      editingFace: null,
    });
  };
  onSave = () => {};

  render() {
    const {
      open,
      onClose,
      rootStore: { faceStore },
    } = this.props;

    const { editingFace } = this.state;
    return (
      <Drawer
        className="FaceManager"
        anchor={"right"}
        open={open}
        onClose={onClose}
      >
        <Box
          paddingTop={3}
          paddingX={3}
          display="flex"
          flexDirection="column"
          height={"100%"}
        >
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Button color="primary" variant="contained" onClick={this.addFace}>
              Add Face
            </Button>
          </Box>
          <Box flexGrow={1}>
            <TableContainer>
              <Table className="FaceManager__table" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Face Name</TableCell>
                    <TableCell align="center">Face Image</TableCell>
                    <TableCell>Operations</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {faceStore.faces.map((face) => (
                    <TableRow key={face.id}>
                      <TableCell component="th" scope="row">
                        {face.name}
                      </TableCell>
                      <TableCell align="center">
                        {face.faceImages.length > 0
                          ? face.faceImages.map((image) => (
                              <img
                                key={image.id}
                                className="FaceManager__item-image"
                                src={image.imageBase64}
                                alt="face"
                              />
                            ))
                          : "No images"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <IconButton onClick={this.editFace(face)}>
                          <Icon>edit</Icon>
                        </IconButton>
                        <IconButton onClick={faceStore.deleteFace(face)}>
                          <Icon>delete</Icon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

        {!!editingFace && (
          <FaceManagementDialog
            open={!!editingFace}
            face={editingFace}
            onClose={this.onClose}
            onSave={this.onSave}
          />
        )}
      </Drawer>
    );
  }
}
