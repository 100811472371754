import jsQR from "jsqr";

export const detectHealthCodeInCanvas = (canvas: HTMLCanvasElement) => {
  const context = canvas.getContext("2d");
  const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
  const code = jsQR(imageData.data, imageData.width, imageData.height, {
    inversionAttempts: "dontInvert",
  });
  return code;
};

export const isHealthCodeValid = (code: string): boolean => {
  return code.includes("/szgd.tgovcloud.com/");
};
