import React, { Component } from "react";
import { Stepper, Step, StepLabel, Box } from "@material-ui/core";
import FaceMask from "./FaceMask";
// import Temperature from "./Temperature";
// import FaceCompare from "./FaceCompare";
import FaceDetected from "./FaceDetected";
import { RouteComponentProps } from "react-router-dom";
import HealthCode from "./HealthCode";
import { observer, inject } from "mobx-react";
import { PropsWithStore } from "store";

interface Props extends PropsWithStore {}
interface State {
  activeStep: number;
  steps: IStep[];
  faceDetected: boolean;
}

interface IStep {
  component: React.ComponentClass;
  name: string;
}

@inject("rootStore")
@observer
export default class BodyCheck extends Component<
  Props & RouteComponentProps,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      steps: [],
      faceDetected: false,
    };
  }

  isStepComplete = (_step, index) => {
    const { activeStep } = this.state;
    return activeStep > index;
  };

  setFaceDetected = (faceDetected) => {
    this.setState({ faceDetected });
  };

  componentDidMount = () => {
    const { rootStore } = this.props;
    const proceduresConfig = rootStore?.configStore?.userConfig?.procedures;
    this.setState({
      steps: [
        ...(proceduresConfig?.greenCode
          ? [
              {
                component: HealthCode,
                name: "健康绿码",
              },
            ]
          : []),
        // {
        //   component: Temperature,
        //   name: "温度检测",
        // },
        // {
        //   component: FaceCompare,
        //   name: "访客匹配",
        // },
        {
          component: FaceMask,
          name: "体温/口罩检测",
        },
      ],
    });
  };

  onNext = (index: number) => () => {
    const { activeStep, steps } = this.state;
    const { history } = this.props;
    this.setState(
      {
        activeStep: index + 1,
      },
      () => {
        if (activeStep >= steps.length - 1) {
          history.push("/success");
        }
      }
    );
  };

  render() {
    const { activeStep, steps, faceDetected } = this.state;

    return (
      <>
        <FaceDetected setFaceDetected={this.setFaceDetected} />
        {!faceDetected && <div className="middle">
          <img src={process.env.PUBLIC_URL + "/zhuxin-1024.png"} alt={"logo"} />
          <h1>欢迎访问！</h1>
        </div>}
        {faceDetected && <div className="BodyCheck">
          {steps.length > 1 && (
            <Stepper activeStep={activeStep}>
              {steps.map((step, index) => {
                return (
                  <Step
                    key={index}
                    completed={this.isStepComplete(step, index)}
                  >
                    <StepLabel>{step.name}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}
          <Box flexGrow={1}>
            {steps.map((step, index) => {
              const ActiveComponent: any = step.component;
              if (activeStep === index) {
                return (
                  <ActiveComponent key={index} onNext={this.onNext(index)} />
                );
              } else {
                return null;
              }
            })}
          </Box>
        </div>}
      </>
    );
  }
}
