import * as faceapi from "face-api.js";

class ZhuxinFaceClass {
  loadModel = async () => {
    await faceapi.nets.ssdMobilenetv1.loadFromUri(
      `${process.env.PUBLIC_URL}/models/face`
    );
    await faceapi.nets.faceLandmark68Net.loadFromUri(
      `${process.env.PUBLIC_URL}/models/face`
    );
    await faceapi.nets.faceRecognitionNet.loadFromUri(
      `${process.env.PUBLIC_URL}/models/face`
    );
    await faceapi.nets.tinyFaceDetector.loadFromUri(
      `${process.env.PUBLIC_URL}/models/face`
    );

    await faceapi.detectSingleFace(
      faceapi.tf.browser.fromPixels(document.getElementById("warmup") as HTMLImageElement).resizeBilinear([1280, 720]),
    ).withFaceLandmarks().withFaceDescriptor();
  };
}

export type ZhuxinFaceDetection = faceapi.FaceDetection;

export const ZhuxinFace = new ZhuxinFaceClass();
