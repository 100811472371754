import faceStore, { FaceStore } from "./faceStore";
import speechStore, { SpeechStore } from "./speechStore";
import commonStore, { CommonStore } from "./commonStore";
import formStore, { FormStore } from "./formStore";
import configStore, { ConfigStore } from "./configStore";
import temperatureStore, { TemperatureStore } from "./temperatureStore";

export class RootStore {
  faceStore: FaceStore;
  speechStore: SpeechStore;
  commonStore: CommonStore;
  formStore: FormStore;
  configStore: ConfigStore;
  temperatureStore: TemperatureStore;
}

export type PropsWithStore = {
  rootStore?: RootStore;
  speechStore?: SpeechStore;
  commonStore?: CommonStore;
  formStore?: FormStore;
  configStore?: ConfigStore;
  temperatureStore?: TemperatureStore;
};

export const rootStore = {
  faceStore,
  speechStore,
  commonStore,
  formStore,
  configStore,
  temperatureStore,
};
