import { observable, action } from "mobx";
import { api } from "config/api";
import localforage from "localforage";
import temperatureStore from "./temperatureStore";
import * as iot from "alibabacloud-iot-device-sdk";
import { ZhuxinMask } from "tf/mask";
import { ZhuxinFace } from "tf/face";

export interface AliyunConfig {
  productKey: string;
  deviceName: string;
  deviceSecret: string;
}

export interface IProcedures {
  doForm: boolean;
  greenCode: boolean;
  humanFace: boolean;
  maskAndTemperature: boolean;
  prefill: boolean;
  mobilePhone: boolean;
  showTerms: boolean;
}

export interface IZhuxinConfig {
  procedures: IProcedures;
  colorStyles: any;
  textStyles: any;
  questions: any[];
  toc: any;
}

export class ConfigStore {
  @observable deviceId: any = null;
  @observable deviceSerial: any = null;
  @observable configLoaded: boolean = false;
  @observable checkDeviceStatus: string = "unChecked";
  @observable userConfig: IZhuxinConfig;
  @observable comPath: string = "";

  updatingDeviceInterval: any;

  @observable productKey: string = "";
  @observable deviceName: string = "";
  @observable deviceSecret: string = "";
  aliyunDevice: any;

  async init() {
    await ZhuxinMask.loadModel();
    await ZhuxinFace.loadModel();
    this.reset();
  }

  async reset() {
    this.configLoaded = false;
    const deviceId = await localforage.getItem("DEVICE_ID");
    const deviceSerial = await localforage.getItem("DEVICE_SERIAL");
    if (deviceId) {
      this.deviceId = deviceId;
      this.deviceSerial = deviceSerial;
      this.startUpdatingDeviceInterval();
    }
    this.comPath = (await localforage.getItem("COM_PATH")) || "";
    const { data: configData } = await api.get("/user-config");
    this.userConfig = configData[0].data;
    this.initializeStyles(configData[0].data);

    const productKey = await localforage.getItem("ALIYUN_PRODUCT_KEY");
    const deviceName = await localforage.getItem("ALIYUN_DEVICE_NAME");
    const deviceSecret = await localforage.getItem("ALIYUN_DEVICE_SECRET");

    this.productKey = (productKey as string) || "";
    this.deviceName = (deviceName as string) || "";
    this.deviceSecret = (deviceSecret as string) || "";

    if (!!productKey && !!deviceName && !!deviceSecret) {
      this.initAliyun({
        productKey,
        deviceName,
        deviceSecret,
      } as any);
    }

    this.configLoaded = true;
    temperatureStore.init();
  }

  async initAliyun(config: AliyunConfig) {
    this.aliyunDevice = iot.device({
      ...config,
      region: "cn-shanghai",
      tls: true,
    });

    this.aliyunDevice.on("connect", () => {
      console.log("Aliyun Device Connected!");

      this.aliyunDevice.postEvent(
        "online",
        {
          online: 1,
        },
        (res) => {
          console.log(`post online event`, res);
        }
      );
    });

    this.aliyunDevice.on("error", (err) => {
      console.log("Aliyun Device Error:", err);
    });
  }

  aliyunEvent = (event, params) => {
    console.log("Aliyun posted Event", event, params);
    this.aliyunDevice?.postEvent(
      event,
      {
        data: JSON.stringify({
          questions: params?.customData.questions,
          temperature: params?.customData.temperature,
          healthCode: params?.customData.healthCode,
        }),
      },
      (res) => {
        console.log(`Aliyun postEvent Response`, res);
      }
    );
  };

  @action
  setAliyunDevice = async ({
    productKey,
    deviceName,
    deviceSecret,
  }: AliyunConfig) => {
    await localforage.setItem("ALIYUN_PRODUCT_KEY", productKey);
    await localforage.setItem("ALIYUN_DEVICE_NAME", deviceName);
    await localforage.setItem("ALIYUN_DEVICE_SECRET", deviceSecret);

    if (!!productKey && !!deviceName && !!deviceSecret) {
      this.initAliyun({
        productKey,
        deviceName,
        deviceSecret,
      });
    }
  };

  @action
  setDeviceInfo = async (deviceId: any, deviceSerial: any) => {
    console.log(deviceId, deviceSerial);
    try {
      const device = await api.post("/device/check", {
        id: parseInt(deviceId, 10),
        serial: deviceSerial,
      });
      if (device) {
        await localforage.setItem("DEVICE_ID", deviceId);
        await localforage.setItem("DEVICE_Serial", deviceSerial);
        this.deviceId = deviceId;
        this.deviceSerial = deviceSerial;
        this.startUpdatingDeviceInterval();
        this.checkDeviceStatus = "checked";
      } else {
        this.checkDeviceStatus = "wrong";
      }
    } catch (err) {
      console.log(err);
      this.checkDeviceStatus = "error";
    }
  };

  @action
  setComPath = async (comPath: string) => {
    this.comPath = comPath;
    await localforage.setItem("COM_PATH", comPath);
  };

  @action
  addDetection = async (answers: any) => {
    const reqBody = {
      deviceId: parseInt(this.deviceId, 10),
      ...answers,
    };
    this.aliyunEvent("visitor", answers);
    try {
      await api.post("/detection", reqBody);
    } catch {}
  };

  startUpdatingDeviceInterval = () => {
    if (this.updatingDeviceInterval) {
      clearInterval(this.updatingDeviceInterval);
    }
    this.updatingDeviceInterval = setInterval(() => {
      this.updateDevice();
    }, 5000);
  };

  updateDevice = async () => {
    if (this.deviceId && this.deviceId !== "") {
      await api.post(`/device/${this.deviceId}/update`);
    }
  };

  getLocation = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position?.coords?.latitude;
          const longitude = position?.coords?.longitude;
          resolve({ latitude, longitude });
        },
        (err) => {
          console.log(err);
          resolve({ latitude: 0, longitude: 0 });
        }
      );
    });
  };

  initializeStyles = (config) => {
    const styles = [...config.colorStyles, ...config.textStyles];
    const styleText = `
      :root {
        ${styles.map((style) => `--${style.key}: ${style.value}`).join(";")}
      }       
    `;
    const head = document.head || document.getElementsByTagName("head")[0];
    const style = document.createElement("style");
    head.appendChild(style);
    style.type = "text/css";
    style.appendChild(document.createTextNode(styleText));
  };
}

export default new ConfigStore();
