import React, { Component } from "react";
import { Box, Stepper, Step, StepLabel } from "@material-ui/core";
import "./FormView.scss";
import { inject, observer } from "mobx-react";
import { PropsWithStore } from "../../store";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface Props extends PropsWithStore {
  onSubmit?: any;
}
interface State {}

@inject("rootStore")
@observer
class FormView extends Component<Props & RouteComponentProps, State> {
  state = {
    activeStep: 0,
    steps: [],
    answers: [],
  };

  audio: HTMLAudioElement;

  play = (url) => {
    if (this.audio) {
      this.audio.pause();
    }
    this.audio = new Audio(url);
    this.audio.play();
  };

  componentDidMount = () => {
    const {
      rootStore: { formStore },
    } = this.props;
    formStore.setFormStart();
    const {
      rootStore: { configStore },
    } = this.props;
    const steps = configStore.userConfig.questions;

    const answers = steps.map((step) =>
      step.type === "multiple-choice" ? -1 : ""
    );

    this.setState({
      steps,
      answers,
    });

    if (steps[0]) {
      this.play(steps[0].audioUrl);
    }
  };

  selectAnswer = (step, choiceIndex) => () => {
    const { answers, steps } = this.state;
    const stepIndex = steps.indexOf(step);
    answers[stepIndex] = choiceIndex;

    this.setState({ answers });

    setTimeout(() => {
      this.nextStep();
    }, 1000);
  };

  handleAnswerChange = (index) => (event) => {
    const answers = [...this.state.answers];
    answers[index] = event.target.value;
    this.setState({
      answers,
    });
  };

  confirmText = () => {
    const { activeStep, answers } = this.state;
    if (answers[activeStep] !== "") {
      this.nextStep();
    }
  };

  nextStep = () => {
    const { activeStep, steps } = this.state;
    if (activeStep < steps.length - 1) {
      this.setState(
        {
          activeStep: activeStep + 1,
        },
        () => {
          this.play(steps[this.state.activeStep].audioUrl);
        }
      );
    }
  };

  submit = () => {
    const { steps, answers } = this.state;
    const questions = steps.map((step, index) => {
      return { ...step, answer: answers[index] };
    });
    const {
      rootStore: { formStore },
    } = this.props;
    formStore.setQuestions(questions);

    if (this.audio) {
      this.audio.pause();
    }

    this.props.onSubmit && this.props.onSubmit();
  };

  backToStep = (index) => () => {
    this.setState({
      activeStep: index,
    });
  };

  isStepComplete = (step, index) => {
    const { answers } = this.state;
    if (step.type === "multiple-choice") {
      return answers[index] !== -1;
    } else if (step.type === "text") {
      return answers[index] !== "";
    }
  };

  isAllStepsComplete = () => {
    const { steps } = this.state;
    let complete = true;
    for (let i = 0; i < steps.length; i++) {
      if (!this.isStepComplete(steps[i], i)) {
        complete = false;
        break;
      }
    }
    return complete;
  };

  render() {
    const { activeStep, steps, answers } = this.state;
    const currentStep = steps[activeStep];
    if (!currentStep) {
      return null;
    }
    return (
      <div className="FormView">
        <Stepper activeStep={activeStep}>
          {steps.map((step, index) => {
            return (
              <Step
                onClick={this.backToStep(index)}
                key={index}
                completed={this.isStepComplete(step, index)}
              >
                <StepLabel></StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <Box>
          <h1>{currentStep.text}</h1>
          {currentStep.type === "multiple-choice" && (
            <div className="FormView__choices">
              {currentStep.choices.map((choice, choiceIndex) => (
                <div
                  key={choiceIndex}
                  onClick={this.selectAnswer(currentStep, choiceIndex)}
                  className={`FormView__choices-button ${
                    answers[activeStep] === choiceIndex
                      ? "FormView__choices-button--active"
                      : ""
                  }`}
                >
                  {choice.label}
                </div>
              ))}
            </div>
          )}

          {currentStep.type === "text" && (
            <div className="FormView__text FormView__choices">
              <input
                onChange={this.handleAnswerChange(activeStep)}
                value={answers[activeStep]}
              />
              {activeStep < steps.length - 1 && (
                <div
                  onClick={this.confirmText}
                  className={`FormView__choices-button`}
                >
                  下一页
                </div>
              )}
            </div>
          )}

          {this.isAllStepsComplete() && (
            <div className="FormView__text FormView__choices">
              <div onClick={this.submit} className={`FormView__choices-button`}>
                提交
              </div>
            </div>
          )}
        </Box>
      </div>
    );
  }
}

export default withRouter(FormView);
